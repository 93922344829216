.container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    flex-wrap: wrap;
    z-index: 1;
}

.container .card {
    position: relative;
    width: 300px;
    height: 400px;
    margin: 30px; 
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    background-color: #222;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    transition: all 0.35s ease-in-out;
}

.card:hover {
    cursor: pointer;
    opacity: 90%;
    box-shadow: 10px 10px 5px -5px rgba(0,0,0,0.59);
    -webkit-box-shadow: 10px 10px 5px -5px rgba(0,0,0,0.59);
    -moz-box-shadow: 10px 10px 5px -5px rgba(0,0,0,0.59);
    

}

.letrasCards{
    margin-top: 50px;
}

.container .card .content {
    padding: 20px;
    text-align: center;
    transition: 0.5s;
}

.container .card .content h3 {
    font-size: 1.8em;
    color: #f2a11e;
    z-index: 1;
}

.container .card .content p {
    font-size: 1em;
    color: #f2a11e;
    font-weight: 300;
}

.container .card .content a {
    position: relative;
    display: inline-block;
    padding: 8px 20px;
    margin-top: 15px;
    background: #fff;
    color: #000;
    border-radius: 20px;
    text-decoration: none;
    font-weight: 500;
}

.learn-more {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
    width: 12rem;
    height: auto;
   }
   
   
   .learn-more .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: #f2a11e;;
    border-radius: 1.625rem;
   }
   
   .learn-more .circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
   }
   
   .learn-more .circle .icon.arrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: 0.625rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;
   }
   
   .learn-more .circle .icon.arrow::before {
    position: absolute;
    content: "";
    top: -0.29rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid #fff;
    border-right: 0.125rem solid #fff;
    transform: rotate(45deg);
   }
   
   .learn-more .button-text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    margin: 0 0 0 1.85rem;
    color: #f2a11e !important;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase;
   }
   
   .learn-more:hover .circle {
    width: 100%;
    color: white;
   }

   .learn-more .circle:hover a {
    color: white !important;
   }
   
   .learn-more:hover .circle .icon.arrow {
    background: #fff;
    transform: translate(1rem, 0);
   }
   
   .learn-more:hover .button-text {
    color: #fff;
   }

   .container .card .content a {
    position: absolute;
    display: block;
    padding: 0px;
    margin-top: 15px;
    background: inherit;
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    margin: 0 0 0 1.85rem;
    color: #282936;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase;
}

.container .card .content a:hover {
    color: white !important;
}


@media (max-width: 600px) {
    .cardsNoMobile {
        display: none;
    }
}
