h1,
h2,
h3,
h4,
h5,
h6 
a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
}

a,
a:active,
a:focus {
    color: #6f6f6f;
    text-decoration: none;
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

img {
max-width: 100%;
height: auto;
}

section {
    padding: 60px 0;
}

.sec-title{
position:relative;
z-index: 999;
margin-bottom:60px;
}

.sec-title .title{
position: relative;
display: block;
font-size: 18px;
line-height: 24px;
color: #2b71ba;
font-weight: 500;
margin-bottom: 15px;
}

.sec-title h2{
position: relative;
display: block;
font-size:40px;
line-height: 1.28em;
color: #222222;
font-weight: 600;
padding-bottom:18px;
}

.sec-title h2:before{
position:absolute;
content:'';
left:0px;
bottom:0px;
width:50px;
height:3px;
background-color:#d1d2d6;
}

.sec-title .text{
position: relative;
font-size: 16px;
line-height: 26px;
color: #848484;
font-weight: 400;
margin-top: 35px;
}

.sec-title.light h2{
color: #ffffff;
}

.sec-title.text-center h2:before{
left:50%;
margin-left: -25px;
}

.list-style-one{
position:relative;}

.list-style-one li{
position:relative;
font-size:16px;
line-height:26px;
color: #222222;
font-weight:400;
padding-left:35px;
margin-bottom: 12px;
}

.list-style-one a {
    text-decoration: none;
}

.list-style-one li:before {
content: "\f058";
position: absolute;
left: 0;
top: 0px;
display: block;
font-size: 18px;
padding: 0px;
color: var(--secondary);
font-weight: 600;
font-style: normal;
font-variant: normal;
text-rendering: auto;
line-height: 1.6;
font-family: "Font Awesome 5 Free";
}

.list-style-one li:hover{
color: var(--secondary);
cursor: pointer;
}

/* estilos seccion servicios */

.list-style-two{
    position:relative;
}
    
.list-style-two li{
    position:relative;
    font-size:14px;
    line-height:26px;
    color: #222222;
    font-weight:400;
    padding-left:30px;
    margin-top: 10px;
    margin-bottom: 10px;
}
    
.list-style-two li:before {
    content: "\f04b";
    position: absolute;
    left: 0;
    top: 0px;
    display: block;
    font-size: 18px;
    padding: 0px;
    color: var(--tertiary);
    font-weight: 600;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1.6;
    font-family: "Font Awesome 5 Free";
}
    
/* .list-style-two li:hover{
    color: #44bce2;
    cursor: pointer;
} */

.list-style-three{
    position:relative;
}
    
.list-style-three li{
    position:relative;
    font-size:14px;
    line-height:26px;
    color: #222222;
    font-weight:400;
    padding-left:30px;
    margin-top: 10px;
    margin-bottom: 10px;
}
    
.list-style-three li:before {
    content: "\f055";
    position: absolute;
    left: 0;
    top: 0px;
    display: block;
    font-size: 18px;
    padding: 0px;
    color: var(--tertiary);
    font-weight: 600;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1.6;
    font-family: "Font Awesome 5 Free";
}
    
.list-style-three li:hover{
    color: var(--secondary);
    cursor: pointer;
}

.btn-style-two{
    position: relative;
    display: inline-block;
    font-size: 17px;
    line-height: 30px;
    color: #ffffff;
    padding: 10px 30px;
    font-weight: 600;
    overflow: hidden;
    letter-spacing: 0.02em;
    background-color: var(--tertiary);
    border-radius: 20px;
    text-decoration: none;
}

.btn-style-two:hover{
        background-color: white;
        color: #2b71ba;
        outline: 1px solid #2b71ba;
        transition: 1s ease all;
}

.btn-style-one{
position: relative;
display: inline-block;
font-size: 17px;
line-height: 30px;
color: #ffffff;
padding: 10px 30px;
font-weight: 600;
overflow: hidden;
letter-spacing: 0.02em;
background-color: var(--primary);
border-radius: 20px;
text-decoration: none;
}

.btn-style-one:hover{
background-color: var(--secondary);
color: white;
outline: 1px solid var(--secondary);
transition: 1s ease all;
}

.about-section{
position: relative;
padding: 120px 0 70px;
}

.about-section .sec-title{
margin-bottom: 45px;
}

.about-section .content-column{
position: relative;
margin-bottom: 50px;
}

.about-section .content-column .inner-column{
position: relative;
padding-left: 30px;
}

.about-section .text{
margin-bottom: 40px;
font-size: 16px;
line-height: 26px;
color: #848484;
font-weight: 400;
}

.about-section .list-style-one{
margin-bottom: 45px;
}

.about-section .btn-box{
position: relative;
}

.about-section .btn-box a{
padding: 15px 50px;
}

.about-section .image-column{
position: relative;
}

.about-section .image-column .text-layer{
position: absolute;
right: -110px;
top: 50%;
font-size: 325px;
line-height: 1em;
color: #ffffff;
margin-top: -175px;
font-weight: 500;
}

.about-section .image-column .inner-column{
position: relative;
padding-left: 120px;
padding-bottom: 125px;
}

.about-section .image-column .inner-column:before{
position: absolute;
left: -75px;
top: 65px;
height: 520px;
width: 520px;
background-image:url(/public/imagenes/imagenes-productos/circle.png);
content: "";
}

@media (max-width: 768px) {

    body{
        overflow-x: hidden !important;
    }
    
	.about-section .image-column .inner-column:before{
        background-image:none;
        content: none;
        }

        .about-section{
            position: relative;
            padding: 50px 0 70px;
        }

        .about-section .image-column .inner-column{
            position: relative;
            padding-left: 10px;
            padding-bottom: 5px;
        }

        /* .image-1 {
            width: 80%;
            left: 10%;
        }
        .image-2 {
            width: 80%;
            left: 10%;
        } */
            
}



.about-section .image-column .image-1{
position: relative;
}

.about-section .image-column .image-2{
position: absolute;
left: 0;
bottom: 0;
}

.about-section .image-column .image-2 img,
.about-section .image-column .image-1 img{
box-shadow: 0 30px 50px rgba(8,13,62,.15);
}

.about-section .image-column .video-link{
position: absolute;
left: 70px;
top: 170px;
}

.about-section .image-column .video-link .link{
position: relative;
display: block;
font-size: 22px;
color: #191e34;
font-weight: 400;
text-align: center;
height: 100px;
width: 100px;
line-height: 100px;
background-color: #ffffff;
border-radius: 50%;
box-shadow: 0 30px 50px rgba(8,13,62,.15);
-webkit-transition: all 300ms ease;
-moz-transition: all 300ms ease;
-ms-transition: all 300ms ease;
-o-transition: all 300ms ease;
transition: all 300ms ease;
}

.about-section .image-column .video-link .link:hover{
background-color: #191e34;
/* color: #f */
}