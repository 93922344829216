.preNavLinks {
    background-color: #222;
    color: white;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    height: 15px;
}

.preNavLinks a {
    text-decoration: none;
    color: #f2a11e;
}

.imagenFont4:before {
    content: "\f138";
    font-size: 15px;
    margin-right: 8px;
    color: #f2a11e;
    font-family: "Font Awesome 5 Free";
}

.imagenFont4 {
    margin-bottom: 0px;
    margin-top: 0px !important;
}

.linkeado2 {
        font-size: 10px !important;
}
